import { date, object, string } from 'yup'
import dayjs from 'dayjs'
import { phoneValidationPattern } from '../../../constants/regexPatterns'
import { getDateOfDispatchMinimumDays } from '../../../utils/getDateOfDispatchMinimumDays'
import { SALUTATION_MAX_LENGTH, TITLE_MAX_LENGTH } from '../../../constants/maxAndMinFieldValues'
import { countriesObject } from '../../../utils/countries'

const countries = countriesObject.map((country) => ({
  value: country.country,
  label: country.country
}))

export const shoppingDeliveryAddressSchema = object({
  companyName: string(),
  note: string()
    .max(100, (value) => `Note must be at most ${value.max} characters`)
    .default(''),
  salutation: string().label('Salutation').max(SALUTATION_MAX_LENGTH).nullable(),
  title: string().label('Title').max(TITLE_MAX_LENGTH).nullable(),
  firstName: string()
    .required('First Name is required')
    .min(2, (value) => `First Name must be at least ${value.min} characters`)
    .max(32, (value) => `First Name must be at most ${value.max} characters`),
  lastName: string()
    .required('Last Name is required')
    .min(2, (value) => `Last Name must be at least ${value.min} characters`)
    .max(32, (value) => `Last Name must be at most ${value.max} characters`),
  email: string().email('Enter a valid email').required('Email is required'),
  phone: string()
    .nullable()
    .matches(phoneValidationPattern, 'Enter a valid phone number'),
  country: string()
    .label('Country')
    .required('Country is required')
    .oneOf(countries.map((country) => country.value)),
  street: string().required('Street and House Number are required'),
  city: string().required('City is required'),
  zip: string().label('Zip Code').required(),
  shippingDate: date()
    .typeError('Shipping Date is required')
    .label('Shipping Date')
    .min(
      dayjs()
        .startOf('day')
        .add(getDateOfDispatchMinimumDays(), 'days')
        .toDate(),
      (value) =>
        `Shipping Date field must be later than ${dayjs(value.min).format(
          'YYYY-MM-DD HH:mm'
        )}`
    )
})
